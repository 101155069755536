@import 'colors';

.btn-primary {
  background-color: $colorP2;
  border-color: $colorP2;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  height: 40px;
  border-radius: 27.5px;
  cursor: pointer;
  &.disabled,
  &:disabled {
    background-color: $colorP2;
    border-color: $colorP2;
    opacity: 0.7;
  }
  &:active:not(:disabled):not(.disabled),
  &:hover {
    border-color: $colorP2;
    background-color: $colorP2;
  }
}

.btn-link {
  color: $colorP2;
  text-decoration: underline;
}

.btn-circle {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 50px;
  font-size: 24px;
  line-height: 1.33;
  font-weight: bold;

  &.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
  }

  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
  }
}
