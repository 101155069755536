@import './shared-variables';


.rc-popup-wrapper {
  .modal-dialog {
    @media(max-width: $mobile_view) {
      margin-top: 30%;
    }
    /************ WARNING POPUP ***************/
    &.warning-popup {
      .modal-content {
        border-radius: 9px;
        .close {
          span {
            display: none;
          }
          height: 40px;
          width: 40px;
          background-size: 40px;
          background-repeat: no-repeat;
          background-image: url(/assets/icons/close.svg);
          right: -12px !important;
          top: -12px !important;
          opacity: 1;
        }
      }
      .modal-body {
        .warning-message {
          .inner-content {
            padding-bottom: calc(78px - 16px); // 16px is the default padding of rc-popup-wrapper
            h1 {
              color: $darkBlue;
              font-size: 16px;
              font-weight: 600;
            }
            p {
              color: $darkBlue;
              font-size: 12px;
              font-weight: 300;
            }
          }
          .footer {
            height: 78px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            background-color: $lightBlue;
            padding: 0 17px;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
            button {
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.modal-backdrop {
  background: none;
}

.rc-radios-control-wrapper {
  .rc-radios-items-wrapper {
    .custom-control {
      margin: 0;
      padding: 0;
      display: inline-block;
      .rc-radios-radio-item-content-wrapper {
        background-color: $colorD7;
        color: $colorP2;
        border-radius: 20px;
        min-width: 65px;
        display: flex;
        justify-content: center;
        align-items: center;

        .custom-control-indicator {
          display: none;
        }
        .custom-control-description {

        }
      }
      .rc-radios-radio-input:focus + .rc-radios-radio-item-content-wrapper {

      }
      .rc-radios-radio-input:checked + .rc-radios-radio-item-content-wrapper {
        background-color: $colorP6;
        color: $colorP3;
        .custom-control-description {

        }
      }
    }
  }
}
