.icon-plus-sm {
  display: inline-block;
  width: 85px;
  height: 85px;
  background-size: 85px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/icon-plus-sm.svg);
}

.icon-lamp-off {
  display: inline-block;
  width: 78px;
  height: 78px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/lamp-off.svg);
}
.icon-lamp-off-no-bg {
  display: inline-block;
  width: 90px;
  height: 90px;
  background-size: 90px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/lamp-off-no-bg.svg);
}
.icon-lamp-on {
  display: inline-block;
  width: 78px;
  height: 78px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/lamp-on.svg);
}
.icon-lamp-on-no-bg {
  display: inline-block;
  width: 90px;
  height: 90px;
  background-size: 90px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/lamp-on-no-bg.svg);
}

.icon-slide-arrows-right {
  background-size: 70px;
  display: inline-block;
  width: 70px;
  height: 13px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/slide-arrows-right.svg);
}

.icon-submit {
  display: inline-block;
  width: 10px;
  height: 14px;
  background-size: 16px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/submit.svg);
}

.icon-close-purple {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-size: 12px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/close-purple.svg);
}

.icon-logout {
  display: inline-block;
  width: 10px;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/logout.svg);
}

.icon-document {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/documents.svg);
}

.icon-employees {
  display: inline-block;
  width: 19px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/employees.svg);
}

.icon-home {
  display: inline-block;
  width: 10px;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/home.svg);
}

.icon-menu {
  display: inline-block;
  width: 20px;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/menu.svg);
}

.icon-notifications {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/notifications.svg);
}

.icon-notifications-inner-dot {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/notifications-inner-dot.svg);
}

.icon-notifications-without-dot {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/notifications-without-dot.svg);
}

.icon-info {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/info.svg);
}

.icon-calendar-with-bg {
  display: inline-block;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/calendar_with_bg.svg);
}

.icon-coming-soon-sm {
  display: inline-block;
  width: 294px;
  height: 314px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/coming-soon-320.svg);
}

.icon-coming-soon-big {
  display: inline-block;
  width: 510px;
  height: 520px;
  background-size: 504px 505px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/coming-soon-desktop.svg);
}

.icon-back {
  display: inline-block;
  width: 13px;
  height: 19px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/back.svg);
}

.icon-left-arrow {
  display: inline-block;
  width: 8px;
  height: 14px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/left-arrow.svg);
}

.icon-approve-small {
  display: inline-block;
  width: 78px;
  height: 78px;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/approve_small.svg);
}

.icon-report-travel-issue-icon {
  display: inline-block;
  width: 10px;
  height: 16px;
  margin: auto;
  background-repeat: no-repeat;
  background-image: url(/assets/icons/report-travel-issue-icon.svg);
}
