.loader-wrapper {
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 10;
}
