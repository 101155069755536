@import './variables';
@import './mixins';
@import './fonts';
@import './btn';
@import './loader';
@import './effects';
@import './colors';
@import './icons';

body {
  direction: $direction;
  text-align: $defaultDir;
  font-family: $defaultFont;
  background: $lightBlue;
  overscroll-behavior: contain;
  @media(max-width: $mobile_view){
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    &.disable-body-scroll {
      position: fixed;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }
}

.show-splash {
  background-image: url("/assets/icons/splash-background.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.full-height {
  height: 100%;
}

.overlay {
  background-color: rgba(0,0,0,0.54);
}

.modal-dialog {
  width: 260px;
  margin-left: auto;
  margin-right: auto;
}

.btn-primary {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  height: 40px;
  border-radius: 27.5px;
}

.inner-page-header {
  background: $colorP2;
  color: $white;
  height: 140px;
  border-bottom-left-radius: 50px;
  overflow: hidden;
  position: relative;

  .entry-back{
    position: absolute;
    left: 28px;
    top: 33px;
  }
  .calendar-icon {
    position: absolute;
    right: 28px;
    top: 27px;
  }

}


.default-page-wrapper {
  text-align: center;
  height: 100%;
  background: $colorP2; // TODO lev remove when upload img
  // background-image: url(/assets/icons/main-bg.png);
  //background-repeat: no-repeat;
  //background-image: url("/assets/icons/header-bg.png");
  //background-size: contain;

  .top-area-wrapper {
    height: 25%;
    // padding: 100px 20px 0;
    position: relative;
    .main-title {
      bottom: 12%;
      width: 100%;
      position: absolute;
      color: $white;
      font-size: 24px;
      line-height: 33px;
      font-weight: 200;
      .bold {
        font-weight: 600;
      }
    }
  }
  .middle-area-wrapper {
    height: 65%;
    background: $white;
    border-radius: 50px 0 0 0;
    box-shadow: 0 2px 15px 0 rgba(127,130,215,0.16);
    .cards-wrapper {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
    }
    .empty-travels {
      color: $darkGray;
      opacity: 0.6;
      font-size: 14px;
      font-weight: 200;
    }
  }
  .bottom-area-wrapper {
    background: $white;
    height: 10%;
    .total-items {
      // opacity: 0.6;
      color: $darkGray;
      font-size: 16px;
      line-height: 38px;
      // font-weight: 200;
    }
  }
}

.slide.slick-slide{
  outline: none;
}
