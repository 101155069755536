
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/Montserrat-Regular.otf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/Montserrat-UltraLight.otf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/Montserrat-Light.otf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/Montserrat-Medium.otf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/Montserrat-SemiBold.otf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/Montserrat-Bold.otf');
}
